var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_vm._v("匠游通行证注册")]),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"用户名","label":"用户名","placeholder":"用户名","rules":[
                { required: true, message: '请输入用户名' },
                { pattern: /\w{6}/, message: '用户名不能少于6位' } ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{attrs:{"type":"password","name":"密码","label":"密码","placeholder":"密码","rules":[
                { required: true, message: '密码不能为空' },
                { pattern: /\w{6}/, message: '密码不能少于6位' } ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"type":"password","name":"重复密码","label":"重复密码","placeholder":"重复密码","rules":[
                { required: true, message: '密码不能为空' },
                { pattern: /\w{6}/, message: '密码不能少于6位' },
                { validator: _vm.validator, message: '两次输入的密码不同' }
            ]},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('div',{staticStyle:{"margin":"12px"}},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("立即注册")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }