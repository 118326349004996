// const HOST = 'http://192.168.0.108:8085'
//const HOST = 'http://localhost:8085'
const HOST = 'https://api-mc.wkgwy.com'
const DATAAPI = {
  REGISTER: '/user/registerbyweb'
}

export function GetApiHost () {
  return HOST
}

export function RegisterApi () {
    var url = GetApiHost() + DATAAPI.REGISTER
    return url
}
