<template>
    <div>
        <div class="title">匠游通行证注册</div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="username"
                name="用户名"
                label="用户名"
                placeholder="用户名"
                :rules="[
                    { required: true, message: '请输入用户名' },
                    { pattern: /\w{6}/, message: '用户名不能少于6位' },
                ]"
            />
            <van-field
                v-model="password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[
                    { required: true, message: '密码不能为空' },
                    { pattern: /\w{6}/, message: '密码不能少于6位' },
                ]"
            />
            <van-field
                v-model="confirmpassword"
                type="password"
                name="重复密码"
                label="重复密码"
                placeholder="重复密码"
                :rules="[
                    { required: true, message: '密码不能为空' },
                    { pattern: /\w{6}/, message: '密码不能少于6位' },
                    { validator, message: '两次输入的密码不同' }
                ]"
            />
            <div style="margin: 12px;">
                <van-button block type="info" native-type="submit"
                    >立即注册</van-button
                >
            </div>
        </van-form>
    </div>
</template>
<script>
import { Form, Field, Button, Toast } from "vant";
import { RegisterApi } from "../../../utils/api/api";
import axios from "axios";
export default {
    components: {
        [Form.name]: Form,
        [Field.name]: Field,
        [Button.name]: Button,
    },
    data() {
        return {
            username: "",
            password: "",
            confirmpassword: "",
            captcha: "",
        };
    },
    mounted: function() {
        var from = "57XWCXAZSQOR";
        if (typeof this.$route.query.from != "undefined") {
            from = this.$route.query.from;
        }
        var that = this;
        var url = RegisterApi();
        this.captcha = new window.TencentCaptcha("2075571995", function(res) {
            if (res.ret === 0) {
                axios
                    .post(url, {
                        from: from,
                        username: that.username,
                        password: that.password,
                        ticket: res.ticket,
                        randstr: res.randstr,
                        autologin:1
                    })
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.code === 0) {
                                Toast.success("注册成功");
                                if (from != "57XWCXAZSQOR") {
                                    var token = response.data.data.access_token;
                                    setTimeout(function() {
                                        window.location.href =
                                            "uniwebview://register?token="+token;
                                    }, 1500);
                                }
                            } else {
                                Toast.fail(response.data.msg);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (that.loading = false));
            }
        });
    },
    methods: {
        validator(val){
          return this.password == val;
        },
        onSubmit() {
            this.captcha.show();
        },
    },
};
</script>
<style lang="less">
.title {
    font-size: 22px;
    padding: 40px 0px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 100%;
}
</style>
